<script>
import {Dropdown} from 'bootstrap';
import {safeNumberConversion as __safety} from "@/utils/tools";
import {mapGetters} from "vuex";
import {onBeforeWindowUnload} from "../utils/beforeWindowUnload";

export default {
  components: {
    GameConfigModal: () => import(/* webpackChunkName: "game-config-modal-v2" */ '@/components/GameConfigModal.vue'),
    HandHistoryModal: () => import(/* webpackChunkName: "hand-history-modal-v2" */ '@/components/Game/Modal/HandHistory.vue'),
    HandHistoryDetailsModal: () => import(/* webpackChunkName: "hand-history-details-modal-v2" */ '@/components/Game/Modal/HandHistoryDetails.vue'),
    PlayerAvatarModal: () => import(/* webpackChunkName: "tournament-info-layer-lobby-v2" */ '@/components/Game/Modal/PlayerAvatar.vue'),
    PlayerNameModal: () => import(/* webpackChunkName: "player-name-modal-v2" */ '@/components/Game/Modal/PlayerName.vue'),
    PlayerRecordModal: () => import(/* webpackChunkName: "player-record-modal-v2" */ '@/components/Game/Modal/PlayerRecord.vue'),
  },
  data() {
    return {
      dropdownValue: false,
      dropdownInstance: null,
      showPlayerNameModal: false,
      targetGameId: 0,
    }
  },
  computed: {
    ...mapGetters(['sessionData']),
    ...mapGetters('user', ['handHistoryModal', 'handHistoryDetailsModal']),
    session() {
      return this.$store.getters['sessionData'] || null;
    },
    userCash() {
      return __safety(this.session.cash || 0);
    },
    avatarImage() {
      if (!this.session) {
        const tempAvatars = [2, 6, 7, 14, 15];
        const tmpAvatar = tempAvatars[Math.floor(Math.random() * tempAvatars.length)];
        return require(`@/assets/v2/img/avatar/avatar${tmpAvatar}.jpg`);
      }

      let avartarNumber = (this.session.photo || '').toString();
      avartarNumber = avartarNumber.match(/\d/gmi).join('');
      avartarNumber = parseInt(avartarNumber);

      return require(`@/assets/v2/img/avatar/avatar${avartarNumber < 10 ? '0' : ''}${avartarNumber}.jpg`);
    },
    playerName() {
      return this.sessionData && this.sessionData.name;
    },
    handHistoryState: {
      get() {
        return this.handHistoryModal;
      },
      set(state) {
        return this.$store.commit('user/SET_HAND_HISTORY_MODAL', state);
      },
    },
    handHistoryDetailsState: {
      get() {
        return this.handHistoryDetailsModal;
      },
      set(state) {
        return this.$store.commit('user/SET_HAND_HISTORY_DETAILS_MODAL', state);
      },
    },
  },
  watch: {
    dropdownValue(newValue) {
      if (newValue) {
        this.showDropdown();
      } else {
        this.hideDropdown();
      }
    },
    // bootstrap 모달 중첩 오류 방지
    handHistoryDetailsState(value) {
      this.handHistoryState = !value;
    },
    playerName: {
      immediate: true,
      handler: 'checkPlayerName',
    },
    showPlayerNameModal() {
      this.$refs.playerNameModal.modalInstance.show();
    }
  },
  methods: {
    showDropdown() {
      this.$nextTick(() => {
        this.dropdownInstance.show();
      })
    },
    hideDropdown() {
      if (this.dropdownInstance) {
        this.$nextTick(() => {
          this.dropdownInstance.hide();
        });
      }
    },
    showPlayerAvatarModal(){
      this.$refs.playerAvatarModal.modal.show();
    },
    showHandHistoryModal() {
      this.onShowHandHistoryModal({viewId: 'handhistory', data: null});
      this.hideDropdown();
    },
    onShowHandHistoryModal({viewId, data}) {
      switch (viewId) {
        case 'handhistory':
          this.handHistoryState = true;
          break;
        case 'handhistorydetails':
          this.handHistoryDetailsState = true;
          if (data && data.roomId) {
            this.targetGameId = data.roomId || 0;
          }
          break;
      }
    },
    checkPlayerName() {
      const isSetName = this.playerName && (this.playerName || '').toString().trim().length > 1;
      this.showPlayerNameModal = !isSetName;
    },
    goHome(url) {
      window.removeEventListener('beforeunload', onBeforeWindowUnload);
      this.$router.push({path: url}).catch(()=>{});
    },
    logout(){
      window.removeEventListener('beforeunload', onBeforeWindowUnload);
      this.$emit('logout'); //부모에게 로그아웃 신호 전달
      // sessionStorage.setItem(`loginToken`, undefined);
      // this.$store.state.loginToken = '';
      this.$store.dispatch('auth_logout');

      if( !window.opener ){
        this.$router.replace({name: 'login'});
      }

      window.close();
    },
    showPlayerRecordModal(){
      this.EventBus.$emit('show-player-record-modal', {displayType: 'all', player: this.sessionData});
      this.hideDropdown();
    },
    setDropdownValue(){
      if (!this.dropdownValue || !this.$refs.dropdown) {
        return;
      }

      this.dropdownValue = false;
      // this.dropdownValue = this.$refs.dropdown.contains(event.target);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.dropdownInstance = this.dropdownInstance || new Dropdown(this.$refs.dropdown, {
        autoClose: true,
        reference: this.$refs.activator
      });

      document.addEventListener('click', this.setDropdownValue);
    });

    this.$nextTick(() => {
      /**
       * bootstrap modal 관련 오류로 인해 전역 body style 처리 해제가 제대로 되지 않는 것으로 추정
       * TODO : 추후 실 원인 파악 및 수정 필요
       */
      if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = null;
        document.body.style.paddingRight = null;
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.setDropdownValue);
  }
}

</script>

<template>
  <header id="header">
    <div class="wrap_header">
      <div class="area_left">
        <a href="javascript:void(0);" @click="goHome('/main')"><img src="@/assets/v2/svg/h1_logo.svg" alt="royal holdum" class="logo" width="142"></a>
      </div>
      <div class="area_right">
        <div>
          <div class="user_name">{{ playerName }}</div>
          <div class="user_point">
            <span class="ico_coin"></span>
            <span class="num">{{ userCash | formatCash }}</span>
          </div>
        </div>
        <div class="dropdown-center member_menu ms-3" style="z-index: 50;">
          <div
            ref="activator"
            id="dropdown"
            class="profile dropdown-toggle"
            @click.prevent.stop="dropdownValue = !dropdownValue"
          >
            <div class="centered">
              <img :src="avatarImage" alt="프로필 이미지">
            </div>
          </div>

          <ul ref="dropdown" class="dropdown-menu" aria-labelledby="dropdown">
            <li @click.prevent.stop="showPlayerAvatarModal">
              <a href="javascript:void(0);" class="dropdown-item">
                <span class="align-middle me-3">
                  <img src="@/assets/v2/svg/ico_person.svg" alt="">
                </span>
                <span class="align-middle">아바타</span>
              </a>
            </li>
            <li @click.prevent.stop="showPlayerRecordModal">
              <a class="dropdown-item" href="javascript:void(0);">
                <span class="align-middle me-3">
                  <img src="@/assets/v2/svg/ico_score.svg" alt="">
                </span>
                <span class="align-middle">나의 전적</span>
              </a>
            </li>
            <li @click.prevent.stop="showHandHistoryModal">
              <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#hand_history">
                <span class="align-middle me-3">
                  <img src="@/assets/v2/svg/ico_history.svg" alt="">
                </span>
                <span class="align-middle">핸드 히스토리</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#game_option">
                <span class="align-middle me-3">
                  <img src="@/assets/v2/svg/ico_option.svg" alt="">
                </span>
                <span class="align-middle">설정</span>
              </a>
            </li>
            <li @click.prevent.stop="logout()">
              <a class="dropdown-item" href="#">
                <span class="align-middle me-3">
                  <img src="@/assets/v2/svg/ico_logout.svg" alt="">
                </span>
                <span class="align-middle">로그아웃</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <GameConfigModal/>
    <HandHistoryModal v-model="handHistoryState" @modal="onShowHandHistoryModal"/>
    <HandHistoryDetailsModal v-model="handHistoryDetailsState" :room-id="targetGameId"/>
    <PlayerNameModal ref="playerNameModal" v-if="showPlayerNameModal" />
    <PlayerAvatarModal ref="playerAvatarModal" />
    <PlayerRecordModal />
  </header>
</template>